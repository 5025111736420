import React, { useState } from 'react';
import './index.css';
import "./App.css";


function App() {
  const [height, setHeight] = useState("");
  const [weight, setWeight] = useState("");
  const [gender, setGender] = useState("");
  const [age, setAge] = useState("");
  const [bmi, setBmi] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();

    const heightInMeters = height / 100;
    const bmi = weight / (heightInMeters * heightInMeters);
    setBmi(bmi.toFixed(2));

    let message = "";
    if (bmi < 18.5) {
      message = "You are underweight.";
    } else if (bmi >= 18.5 && bmi <= 24.9) {
      message = "You are healthy.";
    } else if (bmi >= 25 && bmi <= 29) {
      message = "You are overweight.";
    } else if (bmi >= 30 && bmi <= 34.9) {
      message = "You are obese (class I).";
    } else if (bmi >= 35 && bmi <= 39.9) {
      message = "You are obese (class II).";
    } else {
      message = "You are obese (class III).";
    }
    setMessage(message);
  };

  return (
    <div className="min-h-screen bg-gray-100 flex justify-center items-center">
      <form
        className="bg-white rounded-lg p-8 shadow-md max-w-sm"
        onSubmit={handleSubmit}
      >
        <div className="mb-4">
          <label
            className="block text-gray-700 font-bold mb-2"
            htmlFor="height"
          >
            Height (cm)
          </label>
          <input
            className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="height"
            name="height"
            type="number"
            placeholder="Enter your height"
            required
            value={height}
            onChange={(event) => setHeight(event.target.value)}
          />
        </div>
        <div className="mb-4">
          <label
            className="block text-gray-700 font-bold mb-2"
            htmlFor="weight"
          >
            Weight (kg)
          </label>
          <input
            className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="weight"
            name="weight"
            type="number"
            placeholder="Enter your weight"
            required
            value={weight}
            onChange={(event) => setWeight(event.target.value)}
          />
        </div>
        <div className="mb-4">
          <label
            className="block text-gray-700 font-bold mb-2"
            htmlFor="gender"
          >
            Gender
          </label>
          <select
            className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="gender"
            name="gender"
            required
            value={gender}
            onChange={(event) => setGender(event.target.value)}
          >
            <option value="">Select your gender</option>
            <option value="male">Male</option>
            <option value="female">Female</option>
          </select>
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 font-bold mb-2" htmlFor="age">
            Age
          </label>
          <input
            className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="age"
            name="age"
            type="number"
            placeholder="Enter your age"
            required
            value={age}
            onChange={(event) => setAge(event.target.value)}
          />
        </div>
        <button
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          type="submit"
        >
          Calculate
        </button>
      </form>
      {bmi && (
        <div className="mt-8 bg-white rounded-lg p-8 shadow-md max-w-sm">
          <p className="font-bold mb-2">Your BMI: {bmi}</p>
          <p>{message}</p>
        </div>
      )}
    </div>
  );
}

export default App;
